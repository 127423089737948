<template>
	<LoadboardDashboard />
</template>

<script>
const LoadboardDashboard = () => import(/* webpackChunkName: 'loadboard dashboard'*/ "Components/loadboard/LoadboardDashboard");
export default {
	components: {
		LoadboardDashboard,
	},
};
</script>

<style>
</style>