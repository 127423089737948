<template>
	<v-col cols="12">
		<v-card elevation="0" class="pl-5 pr-5 mt-2" color="transparent">
			<v-row>
				<v-col>
					<v-card elevation="0" class="mb-5" color="transparent">
						<v-card-title class="button font-weight-medium pa-0 blue-grey--text">
							LoadBoard
							<span class="ml-1 warning--text font-weight-bold">View Loads</span>
						</v-card-title>
						<v-card-title class="mt-3 pb-0 pl-0">
							<v-text-field
								hide-details
								flat
								color="blue-grey text-darken-4"
								background-color="white"
								prepend-inner-icon="mdi-magnify"
								v-model="name"
								solo
								placeholder="Search Loads"
								type="text"
								class="caption font-weight-bold mb-2 blue-grey--text text--darken-4"
							></v-text-field>
							<v-spacer></v-spacer>
							<v-btn class="ma-0 caption font-weight-bold mr-2" tile dark color="warning">New Load</v-btn>
							<v-btn class="ma-0 caption font-weight-bold mr-2" tile dark color="warning">Add Driver</v-btn>
							<v-btn class="ma-0 caption font-weight-bold" tile dark color="warning">Add Truck/Trailer</v-btn>
						</v-card-title>
					</v-card>
				</v-col>
			</v-row>
			<v-card elevation="1" class="pl-0 pr-0 pt-2">
				<v-row class="pl-5 pr-5">
					<v-col
						cols="1"
						md="2
            "
						sm="4"
						align="center"
					>
						<v-card color="warning" class="card-icon pt-5 pb-5" dark>
							<v-icon x-large>mdi-monitor-eye</v-icon>
						</v-card>
					</v-col>
					<v-spacer></v-spacer>
				</v-row>

				<v-row align="center">
					<v-col cols="12">
						<v-data-table
							:footer-props="footerOptions"
							:headers="headers"
							:items="Lists"
							no-results-text="No record found."
							class="elevation-0 generate-datatable"
							no-data-text="No record available"
							:loading="loading"
							loading-text="Fetching for Lead records..."
							loader-height="2"
							:options.sync="pagination"
							:items-per-page="pagination.itemsPerPage"
							:server-items-length="pagination.totalItems"
						>
							<template v-if="loading" v-slot:progress>
								<v-progress-linear color="primary" :height="5" indeterminate></v-progress-linear>
							</template>
							<template v-if="!loading" v-slot:body="{ items }">
								<tbody>
									<tr v-for="(item, i) in items" :key="i">
										<!-- NOTE: In every iteration of item, always check if item has content, else it will throw error -->
										<td>
											<div class="font-weight-light caption">
												{{ item.customer }}
											</div>
										</td>
										<td>
											<div class="grey--text font-weight-medium">{{ item.load_id }}</div>
										</td>
										<td>
											<div class="font-weight-light caption">
												{{ item.age }}
											</div>
										</td>
										<td>
											<div class="font-weight-light caption">
												{{ item.driver }}
											</div>
										</td>
										<td>
											<div class="font-weight-light caption">
												{{ item.truck }}
											</div>
										</td>
										<td>
											<div class="font-weight-light caption">
												{{ item.total_weight }}
											</div>
										</td>
										<td>
											<div class="font-weight-light caption">{{ item.delivery_on }}</div>
										</td>
										<td>
											<div class="font-weight-light caption">{{ item.origin }}</div>
										</td>
										<td>
											<div class="font-weight-light caption">{{ item.destination }}</div>
										</td>
										<td>
											<div class="font-weight-light caption">{{ item.total_miles }}</div>
										</td>
										<td>
											<div class="font-weight-light caption">
												{{ item.created_on }}
											</div>
										</td>
										<td>
											<div class="font-weight-light caption">
												{{ item.status }}
											</div>
										</td>
										<td>
											<div class="primary--text caption font-weight-medium">{{ item.agent_assigned }}</div>
										</td>

										<td class="px-2">
											<div class="d-flex">
												<v-btn plain class="primary--text text-uppercase font-weight-bold caption px-1">View</v-btn>
												<v-btn plain class="warning--text text-uppercase font-weight-bold caption px-1">Edit</v-btn>
											</div>
										</td>
									</tr>
								</tbody>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card>
		</v-card>
	</v-col>
</template>

<script>
export default {
	data() {
		return {
			footerOptions: {
				itemsPerPageOptions: [10],
			},
			loading: true,
			pagination: {
				page: 1,
				itemsPerPage: 50,
				totalItems: 10,
			},
			name: "",
			headers: [
				{ text: "Customer", width: 15, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Load#", width: 10, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text " },
				{ text: "Age", width: 10, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Driver", width: 10, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Truck", width: 10, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Total Weight", width: 20, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Delivery on", width: 20, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Origin", width: 20, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Destination", width: 20, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Total Miles", width: 20, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Created On", width: 20, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Status", width: 20, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Agent Assigned", width: 20, sortable: false, divider: true, class: "text-center caption font-weight-bold blue-grey--text" },
				{ text: "Actions", width: 50, sortable: false, align: "center", class: "text-center caption font-weight-bold blue-grey--text" },
			],
			Lists: [
				{
					customer: "John Doe",
					load_id: "123524",
					age: "8 Days 10:12",
					driver: "Dunn Lee",
					truck: "Isuzu",
					total_weight: "4,524 kg",
					delivery_on: "Sept. 5, 2020",
					origin: "New Jersey",
					destination: "New Jersey",
					total_miles: 129.25,
					created_on: "Aug 29 2020 10:45 AM",
					status: "Active",
					agent_assigned: "Lamar Reyes",
				},
			],
		};
	},
	mounted() {
		setTimeout(() => {
			this.loading = false;
		}, 1500);
	},
};
</script>

<style scoped>
.card-icon {
	margin-top: -20px;
	padding-top: 35px;
	padding-bottom: 35px;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-application .text-start {
	text-align: center !important;
}
.v-data-table a {
	text-decoration: none;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr,
.v-data-table__wrapper,
.generate-datatable {
	border-top: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>